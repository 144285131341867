import { useState } from 'react';
import './Cart.css';
import EnquiryForm from './EnquiryForm';

export default function Cart(props){

    const [showEnquiryForm,setShowEnquiryForm] = useState(false);
    const {productCount,productList} = props;

    function handleShowEnq (){
		setShowEnquiryForm(false);
	}


    return (
        <section  className="Cart cartContainer" id="Cart">
            <div className="cartCount">
				{productCount}
			</div>
            {showEnquiryForm && <EnquiryForm productCount={productCount} productList={productList} showEnquiryForm={handleShowEnq}/>}
            <button className="cart" onClick={()=>{setShowEnquiryForm(!showEnquiryForm)}}>
            <i className="bi bi-cart4 "></i></button>
        </section>
    );
}