import React, {useEffect, useState} from "react";
import './Footer.css';

export default function Footer(props){
    const [company,setCompany] = useState([]);

    useEffect(()=>{
        if(company.length === 0) {
            const getCompany = async ()=>{
                await setCompany(props.company);
             }
             getCompany();
        }
    },[props,company.length]);

    return (
        <section id="Footer" className="Footer">
            <p>&copy; 2024 {company.length > 0 && company[0].name} - All Rights Reserved</p>
            <span id="siteseal">
                <script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=0OZtIAOcBUPEKcSkAObNgzuwMrJ2h0Xn5vc4ofx6jVWl5k0itpCXY8jfVkMv">
                </script>
            </span>
        </section>
    );
}