import React, { useEffect, useState } from "react";
import './Contact.css';
import ReachUs from "./MapLocation";
import 'font-awesome/css/font-awesome.min.css';


export default function Contact(props){

    const [company,setCompany] = useState([]);

    useEffect(()=>{
        if(company.length === 0) {
            const getCompany = async ()=>{
                await setCompany(props.company);
             }
             getCompany();
        }
    },[props,company.length]);

    return (
        <section id="Contact" className="Contact">
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-md-5 subpart">
                        <h2>{company.length > 0 && company[0].name}</h2>
                        <div style={{"textAlign":"justify"}}>
                            We are providing premium crackers. We source an extensive range of high-quality crackers from trusted manufacturers, With a keen focus on quality, consistency, and competitive pricing, we stands out as a reliable partner for retailers, wholesalers. Our dedicated team of experts works closely with suppliers to streamline the supply chain, providing efficient logistics and exceptional customer service. Our mission is to bridge the gap between outstanding cracker producers and the ever-evolving needs of our clients, delivering value and satisfaction with every shipment.
                        </div>
                    </div>
                    {company.length > 0  &&
                    <div className="col-md-4 subpart"> 
                        <h2>Contact Us</h2>
                        <div>
                            <a href='mailto:{company[0].email}' style={{"fontStyle":"normal","textDecoration":"none","color":"white"}}>
                                <i className="fa fa-envelope icon"></i> 
                                <div className="iconVal">{company[0].email}</div>
                            </a>
                        </div>
                        <div>
                            <i className="fa fa-mobile-phone icon"></i> 
                            <div className="iconVal">+91 {company[0].mobile_primary}</div>
                        </div>
                        <div>
                            <i className="fa fa-mobile-phone icon"></i> 
                            <div className="iconVal">+91 {company[0].mobile_secondary}</div>
                        </div>
                        <div>
                            <i className="fa fa-map-marker icon">    
                            </i> 
                            <div className="iconVal">{company[0].address}, {company[0].street}</div>
                        </div>
                        <div> 
                            <div className="icon">&nbsp;</div>
                            <div className="iconVal"><span> {company[0].city}, {company[0].state}, {company[0].pincode}</span></div>
                        </div>
                    </div>
                    }
                    <div className="col-md-3">
                        <h2>Reach Us</h2>
                        <ReachUs />
                    </div>
                </div>
            </div>
        </section>
    );
}