import { useState } from "react";
import './EnquiryForm.css';
import ListProducts from "./ListProducts";
import { toast } from "react-toastify";
import {Loader} from "rsuite";
import 'rsuite/dist/rsuite.min.css';

export default function EnquiryForm(props){
    const {productList,productCount} = props;
    const [mobile,setMobile] = useState('');
    const [isDisable,setDisable]= useState(false);
    const [isValidForm,setValidForm]= useState(false);
    const [validationMessage,setMobileValidation] = useState("");
    const [successMessage,SetSuccessMessage] = useState("");
    const acceptMobileNumber = new RegExp(/^\d{10}$/); 
    const [isSpinner,setSpinner] = useState(false);
    

    function handleMobileValidation(m){
        if(acceptMobileNumber.test(m)){
            setMobile(m);
            setDisable(false);
            setValidForm(true);
        }
        else{
            setMobile(m);
            setMobileValidation("Please enter valid Mobile Number!");
            toast.warning("Please enter valid Mobile Number!");
            setDisable(true);
            setTimeout(()=> {
                setMobile('');
                setMobileValidation('');
            },1000);
        }

    }
    async function handleFormValidation(){
        if(mobile.trim() !== '' && isValidForm) {
            setSpinner(true);
            const filteredProductList = productList.filter((prod)=>
                {
                    if(prod.quantity > 0) 
                        return prod;
                    else
                        return false;
                }
            );
            const data = {
                "products" : filteredProductList,
                "mobile" : mobile,
                "productCount" : productCount
            };
            await fetch(process.env.REACT_APP_API_URL+'/products',{
                method : "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body : JSON.stringify(data)
            })
            .then(res => res.json())
            .then( res => { 
                if(res.success)
                {
                    setSpinner(false); 
                    SetSuccessMessage(res.message);
                    toast.success(res.message); 
                }
                else {
                    setSpinner(false); 
                    toast.error(res.message); 
                }
            })
            .catch((error) => {
                setSpinner(false);
                toast.error("POST Product List Response Error : ",error)
            });
            
            setTimeout(()=> {
                setMobile('');
                SetSuccessMessage('');
            },3000);
        }
        else
            toast.warning("Please enter valid Mobile Number!");
    }
    function handleClose(){
        props.showEnquiryForm(false);
    }
    function handleMobileNumber(e) {
        if(!isNaN(e.target.value)){
            setMobile(e.target.value);
        }
        else {
            toast.error("Please Enter Mobile Number");
            setMobileValidation("Please Enter Numbers Only");
            setTimeout(()=> {
                setMobile('');
                setMobileValidation('');
            },3000);
        }
    }

    return (
        <section className="EnquiryForm " id="EnquiryForm">
            {isSpinner && <Loader size="lg" backdrop content="Processing..." />}
            <div className="fw-b m-1">
                <div style={{"width":"80%"}} className="text-start d-inline-block align-top">
                    {productCount > 0 && <h4>Your Cart </h4> }
                </div>
                <div style={{"width":"15%"}} className="text-end d-inline-block align-top">
                	<button className="btn btn-dark"  onClick={handleClose}>
                        <i className="fa fa-close"></i>
                    </button>
                </div>
            </div>
            <form onSubmit={(e)=> e.preventDefault()}>
				<div className="row row-eq-height listRow">
					<ListProducts productCount={productCount} productList={productList}/>
				</div>
				<div className="row row-eq-height enqForm" >
					<div className="row row-eq-height EnqRow">
						<h4 className="fw-b">Reach Out Today!</h4><br/>
                        <h5>Your Enquiry, Our Priority!</h5>
					</div>
					<div className="enqFormContent text-start">
                        <div className="row row-eq-height EnqRow pt-2 text-start">
                            <div className="col-md-12">
                                <p style={{fontWeight:"bold", fontSize:"larger"}}>Provide a contact details to get an Enquiry from our Representative.</p>
                            </div>
                        </div>
                        <div className="row row-eq-height EnqRow pt-3 form-group">
							<div className="col-md-12">
								<label htmlFor="number" className="form-label">Mobile Number: </label><br/>
                                <input type="text" className="form-control" style={{border:"2px solid black"}}  value={mobile} id="number" name="number" required onChange={(e)=>{handleMobileNumber(e)}} onBlur={()=> handleMobileValidation(mobile)}/>
							</div>
                            <p className="text-danger">{validationMessage}</p>
                        </div>
                        {/*<div className="row row-eq-height EnqRow pt-3">
							<div className="col-md-12">
								<input type="text"  style={{border:"2px solid black","borderRadius":"2%","padding":"3px"}}  value={mobile} id="number" name="number" required onChange={(e)=>{handleMobileNumber(e)}} onBlur={()=> handleMobileValidation(mobile)}/>
								
							</div>
							<p className="text-danger">{validationMessage}</p>
						</div>*/}
						<div className="row row-eq-height EnqRow mt-3 text-center form-group">
							<div className="col-12">
								<button className="btn btn-success sendEnq" disabled={isDisable} onClick={handleFormValidation}>Send Enquiry Request</button>
								<p className="text-success">{successMessage}</p>
							</div>
						</div>
					</div>
                </div>
            </form>

        </section>
    );
}