import React, { useEffect, useState } from "react";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import Contact from "./Contact";
import Products from "./Products";



export default function Home (){
    const [products,setProducts] = useState([]);
    const [company,setCompany] = useState([]);
    useEffect(()=>{
        fetch(process.env.REACT_APP_API_URL+'/products',{
            method : "GET"
        })
        .then(res => res.json())
        .then( res => { setProducts(res.products) })
        .catch((error) => {console.log("Error fetching products: ",error)});

        fetch(process.env.REACT_APP_API_URL+'/company',{
            method : "GET"
        })
        .then(res => res.json())
        .then( res => { setCompany(res.company) })
        .catch((error) => {console.log("Error fetching company : ",error)});
    },[]);
    return (
        <div style={{width:"100%","overflow":"hidden"}}>
           
            <Header company={company}/>
            <Body />
            <Products products={products}/>
            <Contact company={company}/>
            <Footer company={company}/>
        </div>
    );
};