import {useEffect, useState} from 'react';
import './AddProducts.css';
import { toast } from 'react-toastify';
//import { Loader } from 'rsuite';


export default function AddProducts(){
    const [products,setProducts] = useState({
        name: '', 
        category:'',
        price: '',
        quantity: '',
        min_qty: 1,
        max_qty: 100,
        image:'',
		image_prv: ''
    });
    const [isDisable,setDisable] = useState(true);
	const [showLoader,setLoader] = useState(false);
	useEffect(()=>{
        function validateForms(){
            if(products.name!== '' && products.category !== '' && products.price !=='' && products.quantity !== '' && 
                products.min_qty !== '' && products.max_qty !== '' && products.image !== '' && products.image !== '{}')
                setDisable(false);
            else
                setDisable(true);
            console.log("products-->",products);
        }
		return validateForms();
	},[products]);

	

    function handleInputs(e,key){
        if(key !== 'image')
            setProducts({...products,[key]:e.target.value});
       else
            setProducts({...products,image:e.target.files[0],image_prv:URL.createObjectURL(e.target.files[0])});
    }

    async function handleAddProduct(){
        if(!isDisable){
			setLoader(true);
            const data = {
                "products" : products
            };
            console.log("data-->",data," string data-->",JSON.stringify(data));
			try {
				await fetch(process.env.REACT_APP_API_URL+'/add-products',{
					method : "POST",
					headers : {
						"content-Type": "multipart/form-data"
					},
					body : JSON.stringify(data)
                    
				})
				.then(res=> res.json())
				.then(res=>{toast.sucess("Product Added Successfully!");})
				.catch((error)=>{toast.error(error)});
			}
			catch(error) {
				toast.error(error);
			}
			finally{
				setLoader(false);
			}
        }
		else
			toast.warning("Please give all the detail to add a product!");
    }
    return (
        <div className="container AddProducts" id="AddProducts" >
			{/*showLoader && <div> 
				<Loader backdrop size="lg" content="Processing..." vertical height="100" width="100"/>
				</div> */}
            <form method="POST" action ="/add-products" onSubmit={(e)=> e.preventDefault()}>
                <div className="container">
                    <div className="row row-eq-height">
                        <div className="col-12 text-center">
                            <h3>ADD PRODUCT showLoader - {showLoader}</h3>
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="p_name" >Product Name</label>
                            <input type="text" name="p_name" id="p_name" value={products.name} onChange={(e) =>{handleInputs(e,"name")}} />
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="category" >Category</label>
                            <select name="category" value={products.category} onChange={(e) =>{handleInputs(e,"category")}}>
                                <option value="">Select Category</option>
                                <option value="Ground_Chakkar">Ground Chakkar</option>
                                <option value="Flower_pots">Flower Pots</option>
                                <option value="Rockets">Rockets</option>
                                <option value="Bombs">Bombs</option>
                                <option value="Sparklers">Sparklers</option>
                                <option value="Gift_Boxes">Gift Boxes</option>
                                <option value="Bijili">Bijili</option>
                                <option value="Sky_Shots">Sky shots</option>
                            </select>
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="price" >Price</label>
                            <input type="text" name="price" id="price" value={products.price} onChange={(e) =>{handleInputs(e,"price")}} />
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="qty" >Quantity</label>
                            <input type="text" name="qty" id="qty" value={products.quantity} onChange={(e) =>{handleInputs(e,"quantity")}} />
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="min_qty" >Minimum order quantity</label>
                            <input type="text" name="min_qty" id="min_qty" value={products.min_qty} onChange={(e) =>{handleInputs(e,"min_qty")}} />
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="max_qty" >Maximum order quantity</label>
                            <input type="text" name="max_qty" id="max_qty" value={products.max_qty}  />
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <label htmlFor="img">Product Image</label>
							<input type="file" name="img" id="img" onChange={(e) =>{handleInputs(e,"image")}}/>
                            {products.image_prv && <img src={products.image_prv} alt={products.image} width="80" height="80"></img>}
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        <div className="col-12">
                            <button className='btn btn-success' disabled={isDisable} onClick={handleAddProduct}>Add Product</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}