import React from "react";

export default function ReachUs () {
    return (
        <section id="ReachUs" className="ReachUs">
            <div className="google-map-code">
                <iframe 
                    id="ReachUs" 
                    title="Srivari Balaji Traders" 
                    name="ReachUs"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d347.79961983561435!2d77.83746081323915!3d9.517112056757098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06d3020ecb19fb%3A0x716d1751429a02a3!2sSrivari%20Balaji%20Traders!5e0!3m2!1sen!2sin!4v1724845447874!5m2!1sen!2sin" 
                    width="100%" 
                    height="100%" 
                    loading="lazy" 
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                />
            </div>
        </section>
    );

};