import React, {useEffect, useState} from "react";
import './Products.css';
import Counter from './Counter';
import Cart from './Cart';
//import ListProducts from "./ListProducts";
import {Loader} from "rsuite";
import 'rsuite/dist/rsuite.min.css';


export default function Products(props){
    const [productCount,setProductCount] = useState(0);
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [IsLoader,setLoader] = useState(false);
    const [LoaderContent,setLoaderContent] = useState("");

    useEffect(()=>{
        if(productCategoryList.length === 0) {
            setLoader(true);
            const getProducts = async ()=>{
                await setProductCategoryList(props.products);
             }
             getProducts();
             if(productCategoryList.length === 0){
                setTimeout(()=>{
                    //setLoader(false);
                    setLoaderContent("Sorry! Unable to load the Products. Please try again later!");
                },3000);
             }
        }
        else {
            setLoader(false);
        }
        const filteredProductList = productCategoryList.filter((prod)=>
            {
                if(prod.quantity > 0) 
                    return prod;
                else
                    return false;
            }
        );
        const quantityArray = filteredProductList.map(p => p.quantity);
        let sumElement =0;
        for (let index = 0; index < quantityArray.length; index++) {
            sumElement = sumElement + parseInt(quantityArray[index]);
            setProductCount(sumElement);
        }
    },[props.products,productCategoryList]);



	async function handleProductQuantity (counterData,index){
		await setProductCategoryList((prs) => {
            return prs.map((pr) => {
                return pr.id === index ? { ...pr, quantity: counterData } : pr;
            });
        });
	}
    const getProductCategoryList = productCategoryList.map(
        (item) => {
            return (
                <div className="card productBox  col-md-3 col-sm-12" key={item.id}>
                    <img src={item.image} alt={item.name} className="card-img-top" />
                    <div className="card-body">
                        <div className="card-title"><Counter getProductQuantity={handleProductQuantity} index={item.id}/> </div>
                        <h4 className="card-content">{item.name} <br/> <span style={{"color":"green"}}> Price: <i style={{"fontWeight":"normal","fontSize":"18px"}} className="fa fa-rupee"></i>{item.price}.00</span></h4>
                    </div>
                    {/*<div className="card-content">
                        <div style={{"fontWeight":"bold","fontSize":"18px"}}></div>
                    </div>*/}
                </div>
            );
        }
    );

    return(
        <section id="Products" className="Products">
            <h3 style={{"textAlign":"center","fontWeight" : "bolder"}}>Our Products</h3>
            <div className="container-fluid" style={{"margin":"0px 2%"}}>
                <div className="row row eq-height">
                    {productCategoryList.length > 0 && getProductCategoryList }
                    {productCategoryList.length === 0 && IsLoader && <Loader size="lg" backdrop content="Products Loading..." />}
                    {/*productCategoryList.length === 0 && !IsLoader && LoaderContent!== '' && <div><h4>{LoaderContent}</h4></div>*/}
                </div>
            </div>
            <Cart productCount={productCount} productList={productCategoryList}/>
            {/*<ListProducts  productList={productCategoryList} productCount={productCount}/>*/}
        </section>
    );
}